import { loginValidators } from "../../../services/login-validators";
import { showErrorToast, showSuccessToast } from "../../../toast/toast-service";
import { errorConstants } from "../../../error-constants";

export const handleRegister = async ({
  registrationFormValues,
  registrationFormValueErrors,
  setRegistrationFormValueErrors,
  refs,
  register,
  intl,
  closeRegistrationSidebar,
}) => {
  const { emailRef, nameRef, passwordRef, universityRef, degreeProgramRef } =
    refs;

  let emailFieldError = false;
  let nameFieldError = false;
  let universityFieldError = false;
  let degreeProgramFieldError = false;
  let pwFieldError = false;
  let agbCheckboxError = false;

  try {
    loginValidators.validateEmail(registrationFormValues.email, intl);
  } catch (err) {
    emailFieldError = err.message;
    emailRef.current.focus();
  }

  try {
    loginValidators.validateName(registrationFormValues.name, intl);
  } catch (err) {
    nameFieldError = err.message;
    if (!emailFieldError) nameRef.current.focus();
  }

  if (registrationFormValues.university === "") {
    universityFieldError = intl.formatMessage({
      id: "login-errors-general-required",
    });
    if (!emailFieldError && !nameFieldError) universityRef.current.focus();
  }

  if (registrationFormValues.degreeProgram === "") {
    degreeProgramFieldError = intl.formatMessage({
      id: "login-errors-general-required",
    });
    if (!emailFieldError && !nameFieldError && !universityFieldError)
      degreeProgramRef.current.focus();
  }

  if (!registrationFormValues.areAGBsChecked) {
    agbCheckboxError = intl.formatMessage({
      id: "registration.AGBs-checked.error",
    });
    showErrorToast(agbCheckboxError);
  }

  try {
    loginValidators.validatePassword(registrationFormValues.password, intl);
  } catch (err) {
    pwFieldError = err.message;
    if (
      !emailFieldError &&
      !nameFieldError &&
      !universityFieldError &&
      !degreeProgramFieldError
    )
      passwordRef.current.focus();
  }

  setRegistrationFormValueErrors({
    email: emailFieldError,
    name: nameFieldError,
    university: universityFieldError,
    degreeProgram: degreeProgramFieldError,
    password: pwFieldError,
    agbs: agbCheckboxError,
  });

  if (
    !emailFieldError &&
    !nameFieldError &&
    !pwFieldError &&
    !universityFieldError &&
    !degreeProgramFieldError &&
    !agbCheckboxError
  ) {
    try {
      await register({
        variables: {
          email: registrationFormValues.email,
          username: registrationFormValues.name,
          password: registrationFormValues.password,
          universities: [registrationFormValues.university],
          degreePrograms: [registrationFormValues.degreeProgram],
          areAGBsChecked: registrationFormValues.areAGBsChecked,
        },
      });
      showSuccessToast(
        "Registered successfully. Please check your mail",
        10000
      );
      closeRegistrationSidebar();
    } catch (err) {
      const graphQLErrorMessage = err?.graphQLErrors?.[0].message;
      const errorMessage = intl.formatMessage(
        {
          id: "registration-errors",
        },
        { error: graphQLErrorMessage }
      );
      showErrorToast(errorMessage);

      if (
        graphQLErrorMessage === errorConstants.registrationEmailAlreadyExists
      ) {
        setRegistrationFormValueErrors({
          ...registrationFormValueErrors,
          email: errorMessage,
        });
        emailRef.current.focus();
      }
    }
  }
};

export const checkRequiredSocialInputsFilled = ({
  registrationFormValues,
  setRegistrationFormValueErrors,
  refs,
  intl,
}) => {
  const { universityRef, degreeProgramRef } = refs;

  let universityFieldError = false;
  let degreeProgramFieldError = false;
  let agbCheckboxError = false;

  if (registrationFormValues.university === "") {
    universityFieldError = intl.formatMessage({
      id: "login-errors-general-required",
    });
    universityRef.current.focus();
  }

  if (registrationFormValues.degreeProgram === "") {
    degreeProgramFieldError = intl.formatMessage({
      id: "login-errors-general-required",
    });
    if (!universityFieldError) degreeProgramRef.current.focus();
  }

  if (!registrationFormValues.areAGBsChecked) {
    agbCheckboxError = intl.formatMessage({
      id: "registration.AGBs-checked.error",
    });
    showErrorToast(agbCheckboxError);
  }

  setRegistrationFormValueErrors({
    university: universityFieldError,
    degreeProgram: degreeProgramFieldError,
    agbs: agbCheckboxError,
  });

  return !universityFieldError && !degreeProgramFieldError && !agbCheckboxError;
};
