export const mergeNewData = (existing, incoming, fieldName) => {
  if (!incoming) return existing;
  if (!existing) return incoming;

  // Ich hab keine Ahnung wieso ich dieses Spreading hier gemacht habe ..
  const { teachings, ...rest } = incoming;
  let result = rest;

  result[fieldName] = [...existing[fieldName]];
  incoming[fieldName].forEach((newData) => {
    if (
      !existing[fieldName]
        .map((existingData) => existingData.__ref)
        .includes(newData.__ref)
    ) {
      result[fieldName].push(newData);
    }
  });

  return result;
};

export const mergeArrays = (existing, incoming) => {
  if (!incoming) return existing;
  if (!existing) return incoming;

  return incoming.filter((object) => !existing.includes(object));
};

export const mergeResponseAttributeArray = (
  existing,
  incoming,
  args,
  attributeName
) => {
  if (!existing) return incoming;
  const newlyMerged = { ...existing, ...incoming };
  newlyMerged[attributeName] = mergeArrays3(
    existing[attributeName],
    incoming[attributeName],
    args
  );
  return newlyMerged;
};
export const mergeResponseAttributeArray2 = (
  existing,
  incoming,
  args,
  attributeName
) => {
  if (!existing) return incoming;
  const newlyMerged = { ...existing, ...incoming };
  newlyMerged[attributeName] = mergeArrays3(
    existing[attributeName],
    incoming[attributeName],
    args
  );
  return newlyMerged;
};

const findInArray = (existingArray, incomingObject) => {
  const existingIds = existingArray.map((m) => m.__ref);
  const found = existingIds.find(
    (existingId) => existingId === incomingObject.__ref
  );
  return existingIds.indexOf(found);
};

// made for notifications etc. where the newest one appears at the top
export const mergeArrays3 = (existing, incoming, { args }) => {
  // This code is taken from https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
  // the original "offset" keyword is changed to "skip" here, to fit this project
  if (!existing) return incoming;

  const existingIds = existing.map((m) => m.__ref);
  let merged = existing ? existing.slice(0) : [];

  if (incoming) {
    // const filteredIncoming = incoming.filter(
    //   (object) => !existingIds.includes(object.__ref)
    // );

    if (args) {
      // Assume an offset of 0 if args.offset omitted.
      const { skip = 0 } = args;
      if (incoming.length > 0) {
        for (let i = 0; i < incoming.length; ++i) {
          const foundElementIndex = findInArray(existing, incoming[i]);
          if (foundElementIndex) {
            merged.splice(foundElementIndex, foundElementIndex + 1);
          }
          merged[skip + i] = incoming[i];
        }
      } else {
        merged = [...existing].splice(0, skip);
      }
    } else {
      // It's unusual (probably a mistake) for a paginated field not
      // to receive any arguments, so you might prefer to throw an
      // exception here, instead of recovering by appending incoming
      // onto the existing array.
      merged.push(...incoming);
    }
  }

  return merged;
};

// this actually works (at least for Chat.messages right now) - newest one appears at the bottom
export const mergeArrays2 = (existing, incoming, { args }) => {
  // This code is taken from https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
  // the original "offset" keyword is changed to "skip" here, to fit this project
  if (!existing) return incoming;

  const existingIds = existing.map((m) => m.__ref);
  const merged = existing ? existing.slice(0) : [];

  if (incoming) {
    const filteredIncoming = incoming.filter(
      (object) => !existingIds.includes(object.__ref)
    );

    if (args) {
      // Assume an offset of 0 if args.offset omitted.
      const { skip = 0 } = args;
      for (let i = filteredIncoming.length - 1; i >= 0; i--) {
        merged.unshift(filteredIncoming[i]);
      }
    } else {
      // It's unusual (probably a mistake) for a paginated field not
      // to receive any arguments, so you might prefer to throw an
      // exception here, instead of recovering by appending incoming
      // onto the existing array.
      merged.push(...filteredIncoming);
    }
  }
  return merged;
};
