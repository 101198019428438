import { FormattedMessage } from "react-intl";
import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { loggedInSidebarState } from "../../../store/atoms";

export const ProfileButton = ({ getUserData, isProfileMenuOpen }) => {
  const [, setIsSidebarOpen] = useRecoilState(loggedInSidebarState);

  return (
    <ProfilePictureTextWrapper>
      <StyledProfilePicture
        src={getUserData.user.profilePicture || undefined}
        onClick={() => {
          console.log("hello");
          setIsSidebarOpen(true);
        }}
      />
      <UsernameWrapper>
        <Username
          title={getUserData.user.username}
          onClick={() => setIsSidebarOpen(!isProfileMenuOpen)}
          isOnVacation={getUserData.user.onHoliday}
        >
          {getUserData.user.onHoliday ? (
            <FormattedMessage id={"your-tutoring.vacation-mode-chat.profile"} />
          ) : (
            getUserData.user.username
          )}
        </Username>
      </UsernameWrapper>
    </ProfilePictureTextWrapper>
  );
};

const ProfilePictureTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 20px 0 20px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin: 0;
  }
`;

const StyledProfilePicture = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border-radius: 100%;
`;

const Username = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 17px;
  color: ${(props) =>
    props.isOnVacation ? "#F19191" : props.theme.mainTextColor};
  cursor: pointer;
  margin-top: 10px;
`;

const UsernameWrapper = styled.div`
  width: 150px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    display: none;
  }
`;
