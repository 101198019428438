import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_DEGREE_PROGRAMS,
  GET_UNIVERSITIES,
} from "../../apollo-client/queries";
import { FormattedMessage, useIntl } from "react-intl";
import Autocomplete from '@mui/material/Autocomplete';
import { loginValidators } from "../../services/login-validators";
import {
  EDIT_USER_NAME,
  EDIT_USER_UNIVERSITIES,
} from "../../apollo-client/mutations";
import { showSuccessToast } from "../../toast/toast-service";
import { useOpenClose } from "../../hooks/use-open-close";
import { DeleteProfileDialog } from "./delete-profile-dialog";
import { useHistory } from "react-router-dom";
import { MobileNavHeader } from "../profile-page/edit-profile-page/mobile-nav-header";
import { ChooseLanguageDropdown } from "../../components/layout/header/choose-language-dropdown";

export const EditProfileUserData = ({ user }) => {
  const [universityOptions, setUniversityOptions] = useState([]);
  const [degreeProgramOptions, setDegreeProgramOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    username: user.username,
    email: user.email,
    university: user.universities[0],
    degreeProgram: user.degreePrograms[0],
    password: "",
    confirmPassword: "",
  });

  const { value: isDeleteAccountOpen, toggle: toggleDeleteAccountOpen } =
    useOpenClose(false);

  const [formValueErrors, setFormValueErrors] = useState({
    username: false,
    email: false,
    university: false,
    degreeProgram: false,
    password: "",
    confirmPassword: "",
  });

  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const universityRef = useRef(null);
  const degreeProgramRef = useRef(null);

  const intl = useIntl();

  const history = useHistory();

  const [getDegreePrograms, { loading: loadingDegreePrograms }] = useLazyQuery(
    GET_DEGREE_PROGRAMS,
    {
      variables: {
        university: formValues.university?.name,
        limit: 999,
      },
      onCompleted: (data) => {
        setDegreeProgramOptions(data.degreePrograms);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const { loading: loadingUniversites } = useQuery(GET_UNIVERSITIES, {
    variables: { limit: 999 },
    onCompleted: async (data) => {
      setUniversityOptions(data.universities);
      await getDegreePrograms();
    },
  });

  const [changeUniversity] = useMutation(EDIT_USER_UNIVERSITIES, {
    update(cache, addContentLinkResponse) {},
  });

  const [changeName] = useMutation(EDIT_USER_NAME, {
    variables: {
      username: formValues.username,
    },
  });

  const handleUsernameInputChange = (event) => {
    const usernameInput = event.target.value;

    setFormValues({
      ...formValues,
      username: usernameInput,
    });
    resetLoginFormErrors();
  };

  const handleEmailInputChange = (event) => {
    const emailInput = event.target.value;

    setFormValues({
      ...formValues,
      email: emailInput,
    });
    resetLoginFormErrors();
  };

  const handleUniversityInputChange = async (event, value) => {
    const universityInput = value;

    setFormValues({
      ...formValues,
      university: universityInput,
    });
    resetLoginFormErrors();
    await getDegreePrograms();
  };

  const handleDegreeProgramInputChange = async (event, value) => {
    const selectedDegreeProgram = value;

    setFormValues({
      ...formValues,
      degreeProgram: selectedDegreeProgram,
    });

    resetLoginFormErrors();
    await getDegreePrograms();
  };

  const resetLoginFormErrors = () => {
    setFormValueErrors({
      username: false,
      email: false,
      password: false,
      name: false,
      university: false,
      degreeProgram: false,
    });
  };

  const openPWChangeForm = () => {
    history.push(`edit/change-pw`);
  };

  const openPictureChange = () => {
    history.push(`edit/picture-change`);
  };

  const handleSaveData = async (e) => {
    console.log("saving user data");
    // must be set for Button.onSubmit method, because otherwise it refreshes the page without waiting for the mutation to succeed
    e.preventDefault();

    let usernameFieldError = false;
    let emailFieldError = false;
    let nameFieldError = false;
    let universityFieldError = false;
    let degreeProgramFieldError = false;

    try {
      loginValidators.validateEmail(formValues.email, intl);
    } catch (err) {
      emailFieldError = err.message;
      emailRef.current.focus();
    }

    setFormValueErrors({
      username: usernameFieldError,
      email: emailFieldError,
      name: nameFieldError,
      university: universityFieldError,
      degreeProgram: degreeProgramFieldError,
    });

    const canFormBeSent = !(
      usernameFieldError ||
      emailFieldError ||
      nameFieldError ||
      universityFieldError ||
      degreeProgramFieldError
    );

    if (canFormBeSent) {
      if (
        user.universities[0]?.name !== formValues.university.name ||
        user.degreePrograms[0]?.name !== formValues.degreeProgram.name
      )
        await changeUniversity({
          variables: {
            universities: [formValues.university.name],
            degreePrograms: [formValues.degreeProgram?.name],
          },
        });
      if (user.username !== formValues.username) {
        await changeName();
      }

      showSuccessToast(
        <FormattedMessage id={"edit-user-data-profile-updated-successfully"} />
      );
    }
  };

  return (
    <UserData>
      <InputTextField
        label={<FormattedMessage id="general.placeholder-user-name" />}
        size="small"
        variant="outlined"
        inputRef={usernameRef}
        type="email"
        value={formValues.username}
        error={formValueErrors.username}
        helperText={formValueErrors.username}
        onChange={handleUsernameInputChange}
      />
      <InputTextField
        label="E-mail"
        size="small"
        variant="outlined"
        inputRef={emailRef}
        type="email"
        value={formValues.email}
        error={formValueErrors.email}
        helperText={formValueErrors.email}
        onChange={handleEmailInputChange}
        disabled
      />
      <InputAtocomplete
        id="unis-autocomplete"
        options={universityOptions}
        loading={loadingUniversites}
        value={formValues.university}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={handleUniversityInputChange}
        disableClearable={true}
        size={"small"}
        type="email"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: "general.university" })}
            inputRef={universityRef}
            variant="outlined"
            error={formValueErrors.university}
            helperText={formValueErrors.university}
          />
        )}
      />
      <InputAtocomplete
        id="degree-program-autocomplete"
        options={degreeProgramOptions}
        loading={loadingDegreePrograms}
        value={formValues.degreeProgram}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={handleDegreeProgramInputChange}
        disableClearable={true}
        size={"small"}
        type="degree-program"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: "registration.your-degree-program",
            })}
            variant="outlined"
            inputRef={degreeProgramRef}
            error={formValueErrors.degreeProgram}
            helperText={formValueErrors.degreeProgram}
          />
        )}
      />
      <LanguageDropdownBox>
        <ChooseLanguageDropdown />
      </LanguageDropdownBox>

      <ActionButton onClick={openPictureChange}>
        <FormattedMessage id={"edit-user-data-profile.picture"} />
      </ActionButton>

      {!user.usesSocialLogin && (
        <ActionButton onClick={openPWChangeForm}>
          <FormattedMessage id={"edit-user-data.change-password"} />
        </ActionButton>
      )}
      <SaveButton onClick={handleSaveData}>
        {" "}
        <FormattedMessage id={"edit-user-data.save"} />
      </SaveButton>

      <DeleteAccountButton onClick={toggleDeleteAccountOpen}>
        {" "}
        <FormattedMessage id={"edit-user-data.delete-account"} />{" "}
      </DeleteAccountButton>
      <DeleteProfileDialog
        toggle={toggleDeleteAccountOpen}
        isOpen={isDeleteAccountOpen}
      />
    </UserData>
  );
};

const LanguageDropdownBox = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
`;

const UserData = styled.div`
  margin-left: 10%;

  margin-top: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-left: 0%;
    width: 80%;
  }
`;

const InputTextField = styled(TextField)`
  && {
    margin-top: 20px;
    font-size: 10px;
    width: 100%;
  }
`;

const InputAtocomplete = styled(Autocomplete)`
  && {
    margin-top: 20px;
    font-size: 10px;
    width: 100%;
  }
`;

const ActionButton = styled(Button)`
  && {
    color: #5b5b5b;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 158px;
    margin-top: 20px;
    align-self: center;
  }
`;

const SaveButton = styled(Button)`
  && {
    color: #ffffff;
    background: #c23d3e;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 158px;
    margin-top: 30px;
    align-self: flex-end;
  }
`;

const DeleteAccountButton = styled(Button)`
  && {
    margin-top: 300px;
    margin-bottom: 45px;
    max-width: 320px;
    align-self: center;
  }
`;
