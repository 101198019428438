import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
  query user($courseId: String) {
    user {
      _id
      username
      onHoliday
      inactiveTeachings(course: $courseId) {
        teachings {
          _id
          price
          description
          acceptsRemote
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query user {
    user {
      _id
      username
      profilePicture
    }
  }
`;

export const GET_USER_CHATS = gql`
  query getUserChats($search: String, $limit: Int, $skip: Int, $_id: String) {
    user {
      _id
      username
      chats(search: $search, limit: $limit, skip: $skip, _id: $_id) {
        chatsCount
        chats {
          _id
          unreadMessagesCount
          type
          otherPrivateChatMember {
            _id
            username
            profilePicture
            onHoliday
          }
          title
          picture
          isBlockedBy {
            _id
            username
          }
        }
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query getMessages($_id: String, $limit: Int, $skip: Int) {
    user {
      _id
      chats(_id: $_id) {
        chats {
          _id
          messagesCount
          unreadMessagesCount
          members {
            username
          }
          messages(limit: $limit, skip: $skip) {
            _id
            author {
              _id
              username
            }
            isAuthor
            message
            wasDelivered
            wasRead
            wasReadByMe
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_USER_HEADER = gql`
  query user {
    user {
      _id
      username
      profilePicture
      onHoliday
      courses {
        courses {
          _id
          title
          university
        }
      }
      newNotificationsCount
      universities {
        name
      }
    }
  }
`;

export const GET_USER_CONTENT = gql`
  query getuUserContent {
    user {
      _id
      courses {
        courses {
          _id
          contentVideos {
            contentVideos {
              _id
              title
              url
              videoThumbnailUrl
            }
          }
          contentQuestions {
            contentQuestions {
              _id
              title
            }
          }
          contentLinks {
            contentLinks {
              _id
              title
              url
              thumbnail
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query getUserGroups {
    user {
      _id
      groups {
        groups {
          _id
          title
          picture
          courses {
            _id
            title
            university
          }
          creator {
            _id
          }
        }
      }
    }
  }
`;

export const GET_USER_EDIT = gql`
  query user {
    user {
      _id
      email
      username
      profilePicture
      usesSocialLogin
      universities {
        name
      }
      degreePrograms {
        _id
        name
        university
      }
    }
  }
`;

export const GET_UNIVERSITIES = gql`
  query universities($name: String) {
    universities(name: $name) {
      name
    }
  }
`;

export const GET_DEGREE_PROGRAMS = gql`
  query degreePrograms(
    $name: String
    $university: String
    $limit: Int
    $skip: Int
  ) {
    degreePrograms(
      name: $name
      university: $university
      limit: $limit
      skip: $skip
    ) {
      _id
      name
      university
    }
  }
`;

export const GET_USER_COURSES = gql`
  query user {
    user {
      _id
      universities {
        name
      }
      courses {
        courses {
          _id
          title
          university
        }
      }
    }
  }
`;

export const GET_USER_UNIVERSITIES = gql`
  query getUserUniversities {
    user {
      _id
      universities {
        name
      }
    }
  }
`;

export const GET_COURSES = gql`
  query getCourses(
    $title: String
    $university: String
    $limit: Int
    $skip: Int
  ) {
    courses(
      title: $title
      university: $university
      limit: $limit
      skip: $skip
    ) {
      coursesCount
      courses {
        _id
        title
        university
      }
    }
  }
`;

export const GET_COURSES_SEARCHING = gql`
  query getCourses(
    $title: String
    $university: String
    $limit: Int
    $skip: Int
  ) {
    courses(
      title: $title
      university: $university
      limit: $limit
      skip: $skip
    ) {
      coursesCount
      courses {
        _id
        title
        university
      }
    }
  }
`;

export const GET_YOUTUBE_VIDEO_LIST = gql`
  query getCourses($id: String, $title: String, $limit: Int, $skip: Int) {
    courses(_id: $id) {
      courses {
        _id
        title
        university
        contentVideos(title: $title, limit: $limit, skip: $skip) {
          contentVideosCount
          contentVideos {
            _id
            title
            contributor {
              username
            }
            url
            description
            videoThumbnailUrl
            youTubeChannelThumbnailURL
            youTubeChannelTitle
            youTubeChannelURL
            course
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_YOUTUBE_VIDEO = gql`
  query getCourses($id: String, $videoId: String) {
    courses(_id: $id) {
      courses {
        _id
        title
        university
        contentVideos(_id: $videoId) {
          contentVideos {
            _id
            title
            contributor {
              username
            }
            url
            isLiked
            isDisliked
            isContributor
            description
            videoThumbnailUrl
            youTubeChannelThumbnailURL
            youTubeChannelTitle
            youTubeChannelURL
            course
            createdAt
            dislikeCount
            likeCount
          }
        }
      }
    }
  }
`;

export const GET_YOUTUBE_VIDEO_COMMENTS = gql`
  query getYoutubeVideoComments($videoId: String, $limit: Int, $skip: Int) {
    contentVideos(_id: $videoId) {
      contentVideos {
        _id
        comments(limit: $limit, skip: $skip) {
          commentsCount
          comments {
            _id
            contentId
            contentType
            contributor {
              username
              profilePicture
            }
            comment
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
            isContributor
            comments {
              commentsCount
            }
          }
        }
      }
    }
  }
`;

export const GET_YOUTUBE_VIDEO_REPLY_COMMENTS = gql`
  query getYoutubeVideoComments(
    $videoId: String
    $commentId: String
    $limit: Int
    $skip: Int
  ) {
    contentVideos(_id: $videoId) {
      contentVideos {
        _id
        comments(_id: $commentId) {
          comments {
            _id
            comments(limit: $limit, skip: $skip) {
              commentsCount
              comments {
                _id
                contentId
                contentType
                contributor {
                  username
                  profilePicture
                }
                comment
                createdAt
                updatedAt
                likeCount
                dislikeCount
                isLiked
                isDisliked
                isContributor
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTENT_LINKS = gql`
  query getCourses($id: String, $title: String, $limit: Int, $skip: Int) {
    courses(_id: $id) {
      courses {
        _id
        title
        university
        contentLinks(title: $title, limit: $limit, skip: $skip) {
          contentLinksCount
          contentLinks {
            _id
            course
            title
            contributor {
              username
            }
            isContributor
            url
            description
            thumbnail
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
          }
        }
      }
    }
  }
`;

export const GET_CONTENT_QUESTIONS = gql`
  query contentQuestions(
    $_id: String
    $title: String
    $limit: Int
    $skip: Int
  ) {
    courses(_id: $_id) {
      courses {
        _id
        title
        contentQuestions(title: $title, limit: $limit, skip: $skip) {
          contentQuestionsCount
          contentQuestions {
            _id
            course
            title
            question
            hasCorrectAnswer
            contributor {
              username
            }
            isContributor
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
            answers(limit: $limit) {
              answersCount
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTENT_QUESTION_ANSWERS = gql`
  query getContentQuestionAnswers(
    $questionId: String
    $limit: Int
    $skip: Int
  ) {
    contentQuestions(_id: $questionId) {
      contentQuestionsCount
      contentQuestions {
        _id
        answers(limit: $limit, skip: $skip) {
          answersCount
          answers {
            _id
            answer
            isCorrect
            createdAt
            contributor {
              username
              profilePicture
            }
            isContributor
            likeCount
            dislikeCount
            isLiked
            isDisliked
            comments(limit: $limit) {
              commentsCount
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTENT_QUESTION_COMMENTS = gql`
  query getContentQuestionAnswers(
    $questionId: String
    $answerId: String
    $limit: Int
    $skip: Int
  ) {
    contentQuestions(_id: $questionId) {
      contentQuestionsCount
      contentQuestions {
        _id
        answers(_id: $answerId) {
          answersCount
          answers {
            _id
            comments(limit: $limit, skip: $skip) {
              commentsCount
              comments {
                _id
                contentId
                contentType
                contributor {
                  username
                  profilePicture
                }
                comment
                createdAt
                updatedAt
                likeCount
                dislikeCount
                isLiked
                isDisliked
                isContributor
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COURSE_TEACHINGS = gql`
  query GetCourseTeachings(
    $course: String
    $limit: Int
    $skip: Int
    $isActive: Boolean
    $onHoliday: Boolean
    $university: String
    $withTeachingsOnly: Boolean
  ) {
    courses(
      _id: $course
      university: $university
      withTeachingsOnly: $withTeachingsOnly
    ) {
      courses {
        _id
        title
        university
        teachings(
          course: $course
          limit: $limit
          skip: $skip
          isActive: $isActive
          onHoliday: $onHoliday
        ) {
          teachingsCount
          subscriberCount
          teachings {
            _id
            teacher {
              _id
              username
              profilePicture
              avgResponseTime
            }
            isActive
            price
            description
            course {
              _id
              title
            }
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
            isTeacher
            scope
            acceptsRemote
          }
        }
      }
    }
  }
`;

export const GET_TEACHING_DETAIL = gql`
  query teachingDetails($_id: String, $course: String, $isActive: Boolean) {
    courses(_id: $course) {
      courses {
        _id
        title
        university
        teachings(_id: $_id) {
          subscriberCount
          teachings {
            _id
            teacher {
              _id
              username
              profilePicture
              avgResponseTime
              universities {
                name
              }
              teachings(isActive: $isActive) {
                teachings {
                  _id
                  course {
                    _id
                    title
                  }
                  isActive
                  price
                  likeCount
                  dislikeCount
                  acceptsRemote
                }
              }
            }
            isActive
            course {
              _id
              title
            }
            acceptsRemote
            price
            description
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
            isTeacher
          }
        }
      }
    }
  }
`;

export const GET_TEACHING_COMMENTS = gql`
  query getTeachingComments($_id: String, $limit: Int, $skip: Int) {
    teachings(_id: $_id) {
      teachings {
        _id
        isTeacher
        comments(limit: $limit, skip: $skip) {
          commentsCount
          comments {
            _id
            contentId
            contentType
            contributor {
              _id
              username
              profilePicture
            }
            comment
            createdAt
            updatedAt
            likeCount
            dislikeCount
            isLiked
            isDisliked
            isContributor
            hasContributorLikedTheContent
            hasContributorDislikedTheContent
          }
        }
      }
    }
  }
`;

export const GET_ADDITIONAL_TEACHINGS = gql`
  query getAdditionalTeachings($_id: String, $isActive: Boolean) {
    teachings(_id: $_id) {
      teachings {
        _id
        teacher {
          _id
          teachings(isActive: $isActive) {
            teachings {
              _id
              course {
                _id
                title
              }
              isActive
              price
              likeCount
              dislikeCount
            }
          }
        }
      }
    }
  }
`;

export const GET_COURSE_SUBSCRIPTION = gql`
  query user($course: String) {
    user {
      _id
      courseSubscriptions(course: $course) {
        newTeachings
        newQuestions
        newVideos
        newLinks
        newGroups
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getUserNotifications($limit: Int, $skip: Int) {
    user {
      _id
      notificationsCount
      notifications(limit: $limit, skip: $skip) {
        _id
        contentId
        notificationType
        course {
          _id
          title
          university
        }
        teachingRequester {
          _id
          username
          profilePicture
        }
        wasSeen
        createdAt
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query courses($courseId: String, $limit: Int, $skip: Int) {
    courses(_id: $courseId) {
      courses {
        _id
        title
        university
        groups(limit: $limit, skip: $skip) {
          groupsCount
          groups {
            _id
            membersCount
            maxMembers
            isActive
            description
            title
            picture
          }
        }
      }
    }
  }
`;

export const GET_GROUPS_SEARCH_BAR = gql`
  query groups($title: String, $limit: Int, $skip: Int) {
    groups(title: $title, limit: $limit, skip: $skip) {
      groupsCount
      groups {
        _id
        title
        courses {
          _id
          title
          university
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  query courses($courseId: String, $groupId: String) {
    courses(_id: $courseId) {
      courses {
        _id
        title
        university
        groups(title: $groupId) {
          groups {
            _id
            isMember
            membersCount
            maxMembers
            description
            title
            meetingTimes {
              day
              time
            }
            isAdmin
            courses {
              _id
              title
              university
            }
            picture
            members {
              _id
              username
              profilePicture
            }
            admins {
              _id
              username
              profilePicture
            }
          }
        }
      }
    }
  }
`;

export const GET_EDIT_GROUP_DATA = gql`
  query getEditGroupData($title: String) {
    groups(title: $title) {
      groups {
        _id
        title
        members {
          _id
          username
          profilePicture
        }
        admins {
          _id
          username
          profilePicture
        }
      }
    }
  }
`;

export const GET_ACTIVE_STUDENTS = gql`
  query getActiveStudents($courseId: String, $limit: Int, $skip: Int) {
    courses(_id: $courseId) {
      courses {
        _id
        title
        university
        activeStudentsData(limit: $limit, skip: $skip) {
          activeStudentsCount
          activeStudents {
            _id
            expiresAt
            description
            student {
              _id
              username
              profilePicture
              activeStudentIn {
                activeStudents {
                  course {
                    _id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
