import { GoogleLogin } from "react-google-login";
import { config } from "../../../config";
import google_logo_icon from "../../../img/google_icon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebook_logo_icon from "../../../img/facebook_logo.svg";
import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import { LOGIN_FACEBOOK, LOGIN_GOOGLE } from "../../../apollo-client/mutations";
import { showErrorToast } from "../../../toast/toast-service";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

export const LoginSocialButtons = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();
  const history = useHistory();

  const loginWithGoogle = async ({ accessToken }) => {
    try {
      const {
        data: { loginGoogle },
      } = await apolloClient.mutate({
        mutation: LOGIN_GOOGLE,
        variables: {
          accessToken,
        },
        // unfortunately this method wont be called, when errors occur, see https://github.com/apollographql/apollo-feature-requests/issues/385
        // onError: (error) => {
        //   history.push("/registration");
        //   showErrorToast(error.message);
        // },
        ignoreResults: true,
      });

      localStorage.setItem("token", loginGoogle.jwt);
      window.location.reload();
    } catch (error) {
      history.push("/registration");
      showErrorToast(error.message);
    }
  };

  const loginWithFacebook = async ({ accessToken }) => {
    const {
      data: { loginFacebook },
    } = await apolloClient.mutate({
      mutation: LOGIN_FACEBOOK,
      variables: {
        accessToken,
      },
    });
    localStorage.setItem("token", loginFacebook.jwt);
    window.location.reload();
  };

  const onGoogleLoginError = () => {
    showErrorToast(
      intl.formatMessage({ id: "login-continue-with-google-error" })
    );
  };

  const onFacebookLoginError = () => {
    showErrorToast(
      intl.formatMessage({ id: "login-continue-with-facebook-error" })
    );
  };

  return (
    <SocialButtonsWrapper>
      <GoogleLogin
        clientId={config.googleOAuthClientID}
        buttonText="LoginButtonWithPopup with Google"
        onSuccess={loginWithGoogle}
        onFailure={() => onGoogleLoginError}
        render={(renderProps) => (
          <SocialLoginButton
            onClick={renderProps.onClick}
            startIcon={
              <img src={google_logo_icon} className="icon_chat_user" />
            }
          >
            <FormattedMessage id="login-continue-with-google" />
          </SocialLoginButton>
        )}
      />

      <FacebookLogin
        appId={config.facebookOAuthAppId}
        autoLoad={false}
        fields="name,email,picture"
        callback={loginWithFacebook}
        onFailure={() => onFacebookLoginError}
        size={"small"}
        render={(renderProps) => (
          <SocialLoginButton
            onClick={renderProps.onClick}
            startIcon={
              <img src={facebook_logo_icon} className="icon_chat_user" />
            }
          >
            <FormattedMessage id="login-continue-with-facebook" />
          </SocialLoginButton>
        )}
      />
    </SocialButtonsWrapper>
  );
};

const SocialButtonsWrapper = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SocialLoginButton = styled(Button)`
  && {
    background: #ffffff;
    border: 0.4px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 10px;
    width: 70%;

    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #666666;
  }
`;
