import img_close_cross from "../../../img/closeCrossIcon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { ChooseLanguageDropdown } from "../../layout/header/choose-language-dropdown";
import { RegisterFields } from "./register-fields";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  checkRequiredSocialInputsFilled,
  handleRegister,
} from "./handleRegister";
import { REGISTER } from "../../../apollo-client/mutations";
import { RegisterSocialButtons } from "./register-social-buttons";

export const RegisterSidebar = ({ isOpen, setIsOpen }) => {
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    university: "",
    degreeProgram: "",
    password: "",
  });
  const [formValueErrors, setFormValueErrors] = useState({
    email: false,
    password: false,
    name: false,
    university: false,
    degreeProgram: false,
    areAGB: false,
  });

  const intl = useIntl();
  const history = useHistory();

  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const universityRef = useRef(null);
  const degreeProgramRef = useRef(null);
  const passwordRef = useRef(null);

  const [registerMutation] = useMutation(REGISTER);

  const register = async () => {
    await handleRegister({
      registrationFormValues: formValues,
      registrationFormValueErrors: formValueErrors,
      setRegistrationFormValueErrors: setFormValueErrors,
      intl,
      closeRegistrationSidebar: closeSidebar,
      register: registerMutation,
      refs: {
        emailRef,
        nameRef,
        passwordRef,
        universityRef,
        degreeProgramRef,
      },
    });
    // go back to landing page
  };

  const checkFormFilledForSocial = () =>
    checkRequiredSocialInputsFilled({
      registrationFormValues: formValues,
      registrationFormValueErrors: formValueErrors,
      setRegistrationFormValueErrors: setFormValueErrors,
      refs: {
        emailRef,
        nameRef,
        passwordRef,
        universityRef,
        degreeProgramRef,
      },
      intl,
    });

  const closeSidebar = () => {
    history.push("/");
    setIsOpen(false);
  };

  return (
    <MenuBox isBurgerMenuOpen={isOpen}>
      <MenuBoxHeader>
        <MenuTitle>
          <FormattedMessage id="general.register-button" />
        </MenuTitle>
        <MenuCloseIcon src={img_close_cross} onClick={closeSidebar} />
      </MenuBoxHeader>
      <RegisterFields
        formValues={formValues}
        formValueErrors={formValueErrors}
        setFormValueErrors={setFormValueErrors}
        setFormValues={setFormValues}
        refs={{
          emailRef,
          nameRef,
          passwordRef,
          universityRef,
          degreeProgramRef,
        }}
        register={register}
      />
      <OrTextWrapper>
        <OrText>
          <FormattedMessage id="sign-up.or" />
        </OrText>
      </OrTextWrapper>
      <RegisterSocialButtons
        checkFormFilledForSocial={checkFormFilledForSocial}
        formValues={formValues}
      />
      <RegisterButtonWrapper>
        <RegisterButton onClick={register}>
          <FormattedMessage id={"general.register-button"} />
        </RegisterButton>
      </RegisterButtonWrapper>
      <Filler />
      <Divider />
      <LinksWrapper>
        <ChooseLanguageDropdown />
        <HelpLink to="/help">
          <FormattedMessage id="menu.info-links.help" />
        </HelpLink>
      </LinksWrapper>
    </MenuBox>
  );
};

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

const OrTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const OrText = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${(props) => props.theme.fourthColor};
`;

const HelpLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.fourthColor};
  text-decoration: none;
  margin-top: 20px;
`;

const Filler = styled.div`
  margin-top: 50px;
`;
const MenuBox = styled.div`
  position: fixed;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isBurgerMenuOpen ? 0 : "100%")});
  transition: transform 0.5s ease;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  z-index: 13;

  @media only screen and (min-width: ${(props) =>
      props.theme.firstBreakpoint + 1}px) {
    width: 40%;
  }
`;

const MenuBoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: ${(props) => props.theme.fourthColor};
`;

const MenuCloseIcon = styled("img")`
  width: 30px;
  margin: 20px 40px;
  cursor: pointer;
  user-select: none;
`;

const RegisterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const RegisterButton = styled(Button)`
  && {
    width: 138px;
    height: 37px;
    text-transform: none;
    font-size: 16px;
    padding: 5px 15px;
    outline: none;
    cursor: pointer;
    border: 1px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 8px;
    color: #f86b6b;
    background-color: #ffffff;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: #ff8181;
      color: white;
    }
  }
`;
