import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import "./index.css";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import messages_de from "./locales/de";
import messages_en from "./locales/en";
import { ToastContainer, Flip } from "react-toastify";
import { muiTheme, theme } from "./theme";
import {
  mergeNewData,
  mergeArrays2,
  mergeResponseAttributeArray,
  mergeResponseAttributeArray2,
} from "./helpers/apollo-helpers";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WEBSOCKET_SERVER_URL,
    connectionParams: () => ({
      authToken: localStorage.getItem("token"),
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          courses: {
            keyArgs: ["_id", "title", "university", "teachings"],
            merge(existing, incoming, args) {
              return mergeResponseAttributeArray2(
                existing,
                incoming,
                args,
                "courses"
              );
            },
          },
        },
      },
      Course: {
        keyArgs: ["_id"],
        fields: {
          teachings: {
            keyArgs: false,
            merge(existing, incoming, args) {
              return mergeResponseAttributeArray(
                existing,
                incoming,
                args,
                "teachings"
              );
            },
          },
        },
      },
      Teaching: {
        keyArgs: ["_id"],
        fields: {
          comments: {
            keyArgs: false,
            merge(existing, incoming, args) {
              return mergeResponseAttributeArray(
                existing,
                incoming,
                args,
                "comments"
              );
            },
          },
        },
      },
      User: {
        keyArgs: ["_id"],
        fields: {
          notifications: {
            merge(existing, incoming, args) {
              return mergeArrays2(existing, incoming, args);
            },
          },
          chats: {
            keyArgs: ["_id"],
            merge(existing, incoming) {
              return mergeNewData(existing, incoming, "chats");
            },
          },
        },
      },
      Chat: {
        fields: {
          messages: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              // This code is taken from https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
              // the original "offset" keyword is changed to "skip" here, to fit this project
              if (!existing) return incoming;

              const existingIds = existing.map((m) => m.__ref);
              const merged = existing ? existing.slice(0) : [];

              if (incoming) {
                const filteredIncoming = incoming.filter(
                  (object) => !existingIds.includes(object.__ref)
                );

                if (args) {
                  // Assume an offset of 0 if args.offset omitted.
                  const { skip = 0 } = args;
                  for (let i = filteredIncoming.length - 1; i >= 0; i--) {
                    merged.unshift(filteredIncoming[i]);
                  }
                } else {
                  // It's unusual (probably a mistake) for a paginated field not
                  // to receive any arguments, so you might prefer to throw an
                  // exception here, instead of recovering by appending incoming
                  // onto the existing array.
                  merged.push(...filteredIncoming);
                }
              }
              return merged;
            },
          },
        },
      },
    },
  }),
});

const messages = {
  de: messages_de,
  en: messages_en,
};

const language = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <ApolloProvider client={client}>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <StyledComponentsThemeProvider theme={theme}>
          <IntlProvider
            locale={localStorage.getItem("language") || language}
            messages={messages[localStorage.getItem("language") || language]}
          >
            <RecoilRoot>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Flip}
              />
              <Routes />
            </RecoilRoot>
          </IntlProvider>
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
