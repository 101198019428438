import { GoogleLogin } from "react-google-login";
import { config } from "../../../config";
import google_logo_icon from "../../../img/google_icon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebook_logo_icon from "../../../img/facebook_logo.svg";
import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import {
  LOGIN_FACEBOOK,
  REGISTER_GOOGLE,
} from "../../../apollo-client/mutations";
import { showErrorToast } from "../../../toast/toast-service";
import { useApolloClient } from "@apollo/client";

export const RegisterSocialButtons = ({
  checkFormFilledForSocial,
  formValues,
}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const checkFormFilledAndPerformOAuth = (performOAuth) => {
    const areRequiredInputsFilled = checkFormFilledForSocial();
    if (areRequiredInputsFilled) {
      performOAuth();
    }
  };

  const registerWithGoogle = async ({ accessToken }) => {
    const {
      data: { registerGoogle },
    } = await apolloClient.mutate({
      mutation: REGISTER_GOOGLE,
      variables: {
        accessToken,
        universities: [formValues.university],
        degreePrograms: [formValues.degreeProgram],
      },
    });

    localStorage.setItem("token", registerGoogle.jwt);
    window.location.reload();
  };

  const registerWithFacebook = async ({ accessToken }) => {
    const {
      data: { registerFacebook },
    } = await apolloClient.mutate({
      mutation: LOGIN_FACEBOOK,
      variables: {
        accessToken,
        universities: [formValues.university],
        degreePrograms: [formValues.degreeProgram],
      },
    });
    localStorage.setItem("token", registerFacebook.jwt);
    window.location.reload();
  };

  const onGoogleLoginError = () => {
    showErrorToast(
      intl.formatMessage({ id: "register-continue-with-google-error" })
    );
  };

  const onFacebookLoginError = () => {
    showErrorToast(
      intl.formatMessage({ id: "register-continue-with-facebook-error" })
    );
  };

  return (
    <SocialButtonsWrapper>
      <GoogleLogin
        clientId={config.googleOAuthClientID}
        buttonText="LoginButtonWithPopup with Google"
        onSuccess={registerWithGoogle}
        onFailure={() => onGoogleLoginError}
        render={(renderProps) => (
          <SocialLoginButton
            onClick={() => {
              checkFormFilledAndPerformOAuth(renderProps.onClick);
            }}
            startIcon={
              <img src={google_logo_icon} className="icon_chat_user" />
            }
          >
            <FormattedMessage id="login-continue-with-google" />
          </SocialLoginButton>
        )}
      />

      <FacebookLogin
        appId={config.facebookOAuthAppId}
        autoLoad={false}
        fields="name,email,picture"
        callback={registerWithFacebook}
        onFailure={() => onFacebookLoginError}
        size={"small"}
        render={(renderProps) => (
          <SocialLoginButton
            onClick={() => {
              checkFormFilledAndPerformOAuth(renderProps.onClick);
            }}
            startIcon={
              <img src={facebook_logo_icon} className="icon_chat_user" />
            }
          >
            <FormattedMessage id="login-continue-with-facebook" />
          </SocialLoginButton>
        )}
      />
    </SocialButtonsWrapper>
  );
};

const SocialButtonsWrapper = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SocialLoginButton = styled(Button)`
  && {
    background: #ffffff;
    border: 0.4px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 10px;
    width: 70%;

    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #666666;
  }
`;
