import React, { useState, Fragment, useRef } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { Link, useRouteMatch } from "react-router-dom";
import { IoIosArrowDown, IoMdMore } from "react-icons/io";
import { ProfilePicture } from "../../../components/common/profile-picture";
import { UNBECOME_TEACHER } from "../../../apollo-client/mutations";
import {
  GET_COURSE_TEACHINGS,
  GET_USER_DATA,
} from "../../../apollo-client/queries";
import chat_icon from "../../../img_new/Chat_Icon.svg";
import accepts_remote_icon from "../../../img/remote.svg";
import { TeacherDescription } from "./teacherDescription";
import { AvgResponseTimeComponent } from "../../teachers-page/avg-response-time-component";
import { EditTeachingDialog } from "../../teachers-page/dialogs/edit-teaching-dialog";
import { Tooltip } from "../../../components/common/tooltip/tooltip";
import { useIsMobile } from "../../../hooks/state-checkers/use-is-mobile";
import { TeacherSettings } from "../teacher-detail-comments/TeacherSettings";
import { useRecoilState } from "recoil";
import { reportState } from "../../../store/atoms";
import { useOutsideMenuCloserWithFunction } from "../../../helpers";
import { FormattedMessage } from "react-intl";
import { CONTENT_TYPES } from "../../../constants";

export const TeacherDetailProfile = ({
  courseId,
  courseTitle,
  handleStartTeacherChat,
  additionalTeacherTeachings,
  props,
  teaching,
}) => {
  const { _id, price, description, isTeacher } = teaching;

  const { profilePicture, username } = teaching.teacher;

  const [isEditTeacherPopupOpen, setIsEditTeacherPopupOpen] = useState(false);

  const isMobile = useIsMobile();

  const { acceptsRemote } = teaching;

  const [unbecomeTeacher] = useMutation(UNBECOME_TEACHER, {
    variables: { course: courseId },
    refetchQueries: [GET_COURSE_TEACHINGS, "teachings", GET_USER_DATA],
  });

  const [showBox, setShowBox] = useState(false);
  const [report, setReport] = useRecoilState(reportState);

  const wrapperRef = useRef(null);
  useOutsideMenuCloserWithFunction(wrapperRef, () => setShowBox(false));

  const deactivateTeacher = async () => {
    await unbecomeTeacher();
    props.history.push(`/courses/${props.match.params.id}/teachers`);
  };

  const NameActions = () => {
    const routeMatch = useRouteMatch();

    return (
      <NameActionsWrapper>
        <NameDetailsWrapper>
          <TeacherName>{username}</TeacherName>

          <NameIconsWrapper>
            {isMobile && <PriceText>{price} €/h</PriceText>}
            {acceptsRemote && (
              <Tooltip tooltipId="common.tooltip.acccepts-remote.hint">
                <AcceptsRemoteImage src={accepts_remote_icon} />
              </Tooltip>
            )}
            <StyledMenuIcon
              size={24}
              // visibility={isDeleteMenuOpen ? "visible" : "hidden"}
              onClick={() => setShowBox(!showBox)}
              style={{ cursor: "pointer" }}
            />
            {showBox && (
              <SettingsBox ref={wrapperRef}>
                <Clickable
                  onClick={() =>
                    setReport({
                      isReportOpen: true,
                      contentId: _id,
                      contentType: CONTENT_TYPES.TEACHING,
                    })
                  }
                >
                  <FormattedMessage id={"general.report"} />
                </Clickable>
              </SettingsBox>
            )}
          </NameIconsWrapper>
        </NameDetailsWrapper>
        <ActionsWrapper>
          {!isMobile && (
            <StyledLink to={`/courses/${routeMatch.params.id}/teachers`}>
              <StyledArrowDown size={28} />
            </StyledLink>
          )}
          <ChatEditClockWrapper>
            <AvgResponseTimeComponent
              avgResponseTime={teaching.teacher.avgResponseTime}
            />
            {!isTeacher ? (
              <ChatImage src={chat_icon} onClick={handleStartTeacherChat} />
            ) : (
              <StyledSettings
                isContributor={true}
                deleteMutation={deactivateTeacher}
                toggleEdit={() =>
                  setIsEditTeacherPopupOpen(!isEditTeacherPopupOpen)
                }
                mirrorSite={true}
              />
            )}
          </ChatEditClockWrapper>
        </ActionsWrapper>
      </NameActionsWrapper>
    );
  };

  return (
    <Fragment>
      <TeacherDetailsContainer>
        <ContentWrapper>
          <LeftSideWrapper>
            <PictureAndPriceWrapper>
              <ProfilePicture
                src={profilePicture || undefined}
                width={isMobile ? 47 : 100}
                height={isMobile ? 47 : 100}
              />
              {!isMobile && (
                <PriceAndRemoteIconWrapper>
                  <PriceText>{price} €/h</PriceText>
                </PriceAndRemoteIconWrapper>
              )}
            </PictureAndPriceWrapper>
            {isMobile && <NameActions />}
          </LeftSideWrapper>
          <CenterWrapper>
            <NameDescriptionWrapper>
              {!isMobile && (
                <TopWrapper>
                  <NameActions />
                </TopWrapper>
              )}

              <TeacherDescription
                description={description}
                additionalTeacherTeachings={additionalTeacherTeachings}
                currentCourseTitle={courseTitle}
                teaching={teaching}
              />
            </NameDescriptionWrapper>
          </CenterWrapper>
        </ContentWrapper>
      </TeacherDetailsContainer>
      <EditTeachingDialog
        teaching={teaching}
        isDialogOpen={isEditTeacherPopupOpen}
        closeDialog={() => setIsEditTeacherPopupOpen(!isEditTeacherPopupOpen)}
      />
    </Fragment>
  );
};

const TeacherDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  margin: 5px auto 0 auto;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 15px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    min-height: 100%;
    box-shadow: 0px 0px 0px;

    border-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin-top: 10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    display: block;
  }
`;

const PictureAndPriceWrapper = styled.div`
  flex-direction: column;
`;

const LeftSideWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  margin-top: 25px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-top: 0px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 110px;
  width: 100%;
`;

const NameDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-left: 10px;
    margin-top: 0px;
  }
`;

const NameActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledMenuIcon = styled(IoMdMore)`
  //margin-right: 10px;
  visibility: ${(props) => props.visibility};
  cursor: pointer;
`;

const PriceText = styled.div`
  font-weight: 600;
  font-size: 30px;

  @media only screen and (max-width: 980px) {
    font-weight: 500;
    font-size: 16px;
  }
`;

const CenterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 31px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-left: 0;
  }
`;

const NameDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const TeacherName = styled.div`
  min-width: 60%;
  font-size: 27px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    font-size: 18px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  align-self: flex-start;

  position: relative;
  margin-top: -10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    position: absolute;
    top: 10px;
    right: 15px;
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const StyledArrowDown = styled(IoIosArrowDown)`
  margin-right: -20px;
  margin-top: 8px;
  color: ${(props) => props.theme.secondaryHeadlineColor};

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-right: 0;
  }
`;

const ChatEditClockWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChatImage = styled("img")`
  width: 60px;
  height: 60px;
  margin-left: 20px;
  cursor: pointer;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    width: 46px;
    height: 46px;
  }
`;

const NameIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  //margin-top: -5px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }
`;

const PriceAndRemoteIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 5px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    flex-direction: row;
  }
`;

const AcceptsRemoteImage = styled("img")`
  width: 25px;
  height: 25px;
  margin-top: 5px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-top: 0px;
    margin-left: 10px;
    width: 23px;
    height: 23px;
  }
`;

const StyledSettings = styled(TeacherSettings)`
  margin-left: 20px;
`;

const SettingsBox = styled.div`
  position: absolute;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-left: ${(props) => (props.mirrorSite ? "-100px" : 0)};
  margin-top: 80px;

  background: #ffffff;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`;

const Clickable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  &:hover {
    color: #f86b6b;
  }
`;

const Text = styled.div`
  margin-right: 25px;
`;
