import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  GET_COURSE_TEACHINGS,
  GET_USER_DATA,
} from "../../apollo-client/queries";
import { PrimarySpinner } from "../../components/spinner/primary-spinner";
import { TeacherSmallComponent } from "./teacher-small-component";
import { useFetchMoreOnScroll } from "../../helpers";
import { BecomeTeacherDialog } from "./dialogs/become-teacher-dialog";
import { showErrorToast } from "../../toast/toast-service";
import { StartTutoringMotivation } from "./start-tutoring-motivation";
import { VacationDeactivationDialog } from "../profile-page/profile-page-teachings/vacation-deactivation-dialog";
import { useOpenClose } from "../../hooks/use-open-close";
import { usePleaseLoginInfo } from "../../hooks/use-please-login-info";
import { theme } from "../../theme";
import { TEACHING_SCOPES } from "../../constants2";
import { ContentContainer } from "../../components/layout/content-container";
import { MainLayout } from "../../components/layout/main-layout";

export const TeachersPage = (props) => {
  const [isBecomeTeacherPopupOpen, setIsBecomeTeacherPopupOpen] =
    useState(false);

  const match = useRouteMatch();

  const {
    data: teachingsData,
    error,
    loading: loadingTeachings,
    fetchMore,
  } = useQuery(GET_COURSE_TEACHINGS, {
    variables: {
      course: match.params.id,
      limit: 15,
      isActive: true,
      onHoliday: false,
    },
    pollInterval: 60000 * 5, // every 60 seconds x 5
    fetchPolicy: "cache-and-network",
  });

  const { data: userData } = useQuery(GET_USER_DATA, {
    variables: {
      courseId: match.params.id,
    },
  });

  const {
    value: isDeactivateVacationDialogOpen,
    toggle: toggleVacDeactivationDialog,
  } = useOpenClose(false);

  const { openPleaseLoginInfo } = usePleaseLoginInfo();

  let course = teachingsData?.courses?.courses?.[0] ?? [];
  const teachings = teachingsData?.courses?.courses?.[0]?.teachings?.teachings;
  const teachingsCount =
    teachingsData?.courses?.courses?.[0]?.teachings?.teachingsCount;

  useFetchMoreOnScroll(async () => {
    if (teachings?.length < teachingsCount) {
      await fetchMore({
        variables: {
          skip: teachings.length,
        },
      });
    }
  });

  const areTeachingsForModuleAvailable = () => {
    return Boolean(teachings?.[0]?.scope === TEACHING_SCOPES.IN_MODULE);
  };

  const handleBecomeTeacherClick = async (e, userData) => {
    if (!userData) {
      openPleaseLoginInfo();
      return;
    }

    if (userData && isAlreadyTeaching(teachings, userData)) {
      showAlreadyTeacherError();
      return;
    }
    if (userData && userData.user.onHoliday) {
      toggleVacDeactivationDialog();
    } else {
      setIsBecomeTeacherPopupOpen(true);
    }
  };

  const showAlreadyTeacherError = () => {
    showErrorToast(
      <FormattedMessage id={"add-teacher-button.already-tutor-error"} />
    );
  };

  const isAlreadyTeaching = (teachings, userData) => {
    return Boolean(
      userData &&
        teachings?.find(
          (teaching) =>
            teaching.teacher._id === userData.user._id &&
            teaching.scope === "IN_MODULE"
        )
    );
  };

  const shouldShowMotivationOnTop = () => {
    const areNoTeachingsAvailable = Boolean(
      teachings.length === 0 ||
        teachings?.[0]?.scope !== TEACHING_SCOPES.IN_MODULE
    );
    return areNoTeachingsAvailable;
  };

  const shouldShowOtherTeachingsText = (teaching, i, arr) => {
    return (
      (i === 0 && teaching.scope !== "IN_MODULE") ||
      (i !== 0 &&
        arr[i - 1].scope === "IN_MODULE" &&
        arr[i].scope !== "IN_MODULE")
    );
  };

  const shouldShowCourseTitle = (teaching, i, arr) => {
    return (
      (i === 0 && teaching.scope !== "IN_MODULE") ||
      (i !== 0 && arr[i - 1].course.title !== arr[i].course.title)
    );
  };

  return (
    <MainLayout
      title={course.title}
      university={course.university}
      subscriberCount={course.teachings ? course.teachings.subscriberCount : ""}
      loading={loadingTeachings}
      error={error}
      props={props}
    >
      <ContentContainer>
        <Container>
          <TeacherContainer>
            {!error && teachings && shouldShowMotivationOnTop() && (
              <TeachersWrapper>
                <StartTutoringMotivation
                  subscriberCount={
                    course.teachings ? course.teachings.subscriberCount : ""
                  }
                  areTeachingsAvailable={areTeachingsForModuleAvailable()}
                />
              </TeachersWrapper>
            )}

            {!error &&
              teachings?.map((teaching, i, arr) => {
                // eventuell array nach IN_MODULE filtern und ab einer length von x oben das nicht mehr anzeigen (StartTutoringMotivation
                // funktion eventuell aus dem conditional machen => handleIsInModule(teachingScope, i, arr) oder so

                return (
                  <TeachersWrapper key={"LinkWrapper_" + i}>
                    {shouldShowOtherTeachingsText(teaching, i, arr) && (
                      <FurtherTeachingsText>
                        <FormattedMessage id={"teacher.further-teachings"} />
                      </FurtherTeachingsText>
                    )}
                    {shouldShowCourseTitle(teaching, i, arr) && (
                      <OtherModuleText>{teaching.course.title}</OtherModuleText>
                    )}
                    <TeacherLink
                      key={"link_" + i}
                      to={`/courses/${teachingsData.courses.courses[0]._id}/teachers/${teaching._id}`}
                    >
                      <TeacherSmallComponent
                        key={"TeacherComponent_" + i}
                        teaching={teaching}
                        showSmallTeacherComponent={true}
                        courseTitle={teachingsData.courses.courses[0].title}
                        hideLine={i === arr.length - 1}
                      />
                    </TeacherLink>
                  </TeachersWrapper>
                );
              })}
            {loadingTeachings && <StyledPrimarySpinner />}
          </TeacherContainer>
        </Container>
        <VacationDeactivationDialog
          isDialogOpen={isDeactivateVacationDialogOpen}
          toggleDialog={toggleVacDeactivationDialog}
        />
      </ContentContainer>

      {teachings && (
        <AddTeacherButtonWeb
          disabled={isAlreadyTeaching(teachings, userData)}
          onClick={(e) =>
            handleBecomeTeacherClick(e, userData, props.match.params.id)
          }
        >
          <FormattedMessage id={"teacher.add-new-teacher"} />
        </AddTeacherButtonWeb>
      )}

      {isBecomeTeacherPopupOpen && (
        <BecomeTeacherDialog
          closeDialog={() => setIsBecomeTeacherPopupOpen(false)}
          isDialogOpen={isBecomeTeacherPopupOpen}
          user={userData && userData.user}
          courseId={props.match.params.id}
          inactiveTeaching={userData.user.inactiveTeachings.teachings[0]}
        />
      )}
    </MainLayout>
  );
};

const Container = styled.div`
  height: 100%;
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  height: 100px;
  width: 100px;
  margin: 20px auto;
`;

const TeacherContainer = styled.div``;

const TeachersWrapper = styled.div`
  width: 75%;
  margin: 0 auto;

  &:last-child {
    padding-bottom: 150px;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FurtherTeachingsText = styled.div`
  font-size: ${(props) => props.theme.smallHeaderFontSize};
  padding-top: 30px;
  border-top: 1px solid #acacac;
  text-align: start;
  width: 100%;
  color: #e87975;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    padding-left: 10px;
  }
`;

const OtherModuleText = styled.div`
  font-size: ${(props) => props.theme.listHeaderFontSize};
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: start;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
  }
`;

//eventuell wrappen, damit nicht 100% clickable sind
const TeacherLink = styled(Link)`
  text-decoration: none;
  color: ${theme.mainTextColor};
  margin: 0;
  width: 100%;
`;

const AddTeacherButtonWeb = styled.div`
  position: fixed;
  bottom: 30px;
  right: 10%;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled ? "#cccccc" : props.theme.mainColor};
  background-size: 200% 100%;
  background-position: right bottom;
  color: white;
  margin: 0 10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-size: 14px;
    line-height: 19px;
    height: 50px;
    max-width: 100px;
    right: 2%;
    position: fixed;
    bottom: 70px;
  }
`;
